import axiosClient from './axiosClient';

const shopeApi = {
    getProductInfo: (link) => {
        const baseURL = 'shopee/product-commission';
        return axiosClient.post(baseURL, {
            link,
        });
    },
    getProductByKeyword: (keyword, sortType) => {
        const baseURL = 'shopee/get-product-by-keyword';
        return axiosClient.post(baseURL, {
            keyword,
            sortType,
        });
    },
    getRecommendProductList: () => {
        const baseURL = 'shopee/recommend-product-list';
        return axiosClient.get(baseURL);
    },
    getRecommendShopList: (size) => {
        const baseURL = `shopee/recommend-shop-list?size=${size || ''}`;
        return axiosClient.get(baseURL);
    },
    getLinkAff: (link) => {
        const baseURL = 'shopee/get-link';
        return axiosClient.post(baseURL, {
            link,
        });
    },
    getLinkAffVideo: (link) => {
        const baseURL = 'shopee/get-link-shopeevideo';
        return axiosClient.post(baseURL, {
            link,
            isVideo: true,
        });
    },
    findOrder: (orderId) => {
        const baseURL = 'shopee/find_order';
        return axiosClient.post(baseURL, {
            orderId,
        });
    },
    getGhostLinkAff(link) {
        const baseURL = 'ghostshopee/get_link';
        return axiosClient.post(baseURL, {
            link,
        });
    },
    convertUrlAff(listUrl) {
        const baseURL = 'shopee/convert_url_list';
        return axiosClient.post(baseURL, {
            listUrl,
        });
    },
};

export default shopeApi;
