import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import ProductCard from './ProductCard';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SearchResult({
    productList,
    isLoading,
    platform,
    errorProduct,
    notFoundMsg,
}) {
    let productInfo = {};

    switch (platform) {
        case 'shopee':
            productInfo = { productLink: 'https://shopee.vn/' };
            break;
        case 'lazada':
            productInfo = errorProduct || productInfo;
            break;
        default:
            break;
    }

    const { isLogined } = useSelector((state) => state.auth);
    const handleSubmitToMerchant = () => {
        //dispatch action
        localStorage.setItem('redirectPlatform', platform);
        localStorage.setItem('product_info', JSON.stringify(productInfo));
    };

    return (
        <div>
            <span className="font-semibold">Kết quả tìm kiếm</span>
            <div className="flex flex-wrap">
                {productList.map((el, index) => (
                    <div
                        key={index}
                        className="w-1/2 p-1 sm:w-1/3 md:w-1/4 lg:w-1/5"
                    >
                        <ProductCard productInfo={el} platform={platform} />
                    </div>
                ))}
                {isLoading && (
                    <div className="w-1/2 p-1 sm:w-1/3 md:w-1/4 lg:w-1/5">
                        <ProductCard isLoading={isLoading} productInfo={{}} />
                    </div>
                )}
            </div>
            {productList.length > 0 && (
                <div>
                    <p className="p-1 bg-primary-100 text-primary-600 text-center text-sm">
                        <span>Lưu ý: Một số shop có giới hạn chiết khấu.</span>
                        <span className="flex gap-1 justify-center items-center">
                            <FontAwesomeIcon icon={faLightbulb} />
                            <span>
                                Tạo nhiều đơn sẽ được nhiều chiết khấu hơn đó
                                nhen!
                            </span>
                        </span>
                    </p>
                </div>
            )}
            {productList.length === 0 && !isLoading && (
                <div className="flex justify-center items-center flex-col gap-1 text-slate-400 text-4xl py-6 px-4">
                    <FontAwesomeIcon icon={faBox} />
                    <span className="text-sm text-center">
                        {notFoundMsg ||
                            'Oh no! Không tìm thấy thứ gì cả. Bạn thử lại nhé.'}
                    </span>
                    {platform !== 'tiktok' && (
                        <div className="text-sm flex flex-col justify-center items-center gap-3">
                            <div className="p-2 bg-primary-100 rounded-lg text-primary-500">
                                <span>
                                    {` Tin vui! Hiện tại, hầu hết các sản phẩm của ${platform.toUpperCase()}
                                đều được chiết khấu từ 2% đến 40%. Bạn chỉ cần
                                click vào nút Mua ngay, ChietkhauPro sẽ ghi nhận
                                tất cả các đơn hàng của bạn.`}
                                </span>
                            </div>
                            <Link
                                target={isLogined ? '_blank' : '_self'}
                                to={
                                    isLogined
                                        ? '/redirect'
                                        : `/login?redirect=${platform}`
                                }
                                onClick={handleSubmitToMerchant}
                                className="p-3 rounded-lg bg-primary-600 text-white"
                            >
                                <span>{`Truy cập ${platform.toUpperCase()} ngay `}</span>
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default SearchResult;
