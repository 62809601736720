import axiosClient from './axiosClient';

const gameApi = {
    gameList() {
        const baseURL = 'game/game_dashboard';
        return axiosClient.post(baseURL);
    },
    topOfWeek(username) {
        const baseURL = `game/top_of_week?username=${username || ''}`;
        return axiosClient.get(baseURL);
    },
    getUserAnswers(gameId) {
        const baseURL = 'game/user_answers';
        return axiosClient.post(baseURL, { gameId });
    },
};

export default gameApi;
