import React from 'react'

const ShopLoading = ({isLoadingShop}) => {
  return (
    <div className="flex flex-wrap">
    {isLoadingShop &&
        new Array(8).fill('').map((shop, index) => (
            <div
                key={index}
                className="flex flex-col relative w-1/4 sm:w-1/6 md:w-1/6 lg:w-1/6 bg-white border-r border-b last:border-r-0"
            >
                <div className="p-6 w-full aspect-square flex justify-center items-center">
                    <div className="bg-slate-300 w-full aspect-square animate-pulse"></div>
                </div>
                <div className="flex flex-col w-full items-end text-xs px-1 pb-1 pt-2 gap-1">
                    <div className="flex-1 flex justify-center items-center  animate-pulse bg-slate-200 w-1/3 p-1"></div>
                    <div className="flex flex-1 justify-center items-center  animate-pulse bg-slate-200 w-1/2 p-1"></div>
                </div>
            </div>
        ))}
</div>
  )
}

export default ShopLoading