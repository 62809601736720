import axios from 'axios';

const BaseUrl = process.env.REACT_APP_API_SHORT_LINK_URL;
const shortLinkApi = {
    createShortLink(url) {
        return axios.post(BaseUrl + '/short_link/new_short_link', {
            link: url,
        });
    },
};

export default shortLinkApi;
