import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import SearchInput from '../../../components/Ecommerce/SearchInput';
import SearchResult from '../../../components/Ecommerce/SearchResult';
import shopeApi from '../../../api/shopeeApi';
import Categories from './Categories';
import Modal from '../../../components/Modal';
import JoinGroup from '../../../components/Modal/popup/JoinGroup';
import TimeLine from '../../../components/TimeLine/TimeLine';

function Shopee() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('link');
    const [productList, setProductList] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setProductList([]);
        const fetchProductInfoByLink = async () => {
            setisLoading(true);
            try {
                const { data } = await shopeApi.getProductInfo(searchTerm);
                setProductList([data.productInfo]);
            } catch (error) {
                console.log(error);
                setProductList([]);
            }
            setisLoading(false);
        };

        const fetchProductListByKeyword = async () => {
            setisLoading(true);
            try {
                const { data } = await shopeApi.getProductByKeyword(searchTerm);
                setProductList(data.productList);
            } catch (error) {
                console.log(error);
                setProductList([]);
            }
            setisLoading(false);
        };

        if (searchType === 'link' && searchTerm) {
            fetchProductInfoByLink();
        }

        if (searchType === 'product' && searchTerm) {
            fetchProductListByKeyword();
        }
    }, [searchTerm, searchType]);
    return (
        <div className="h-[100dvh] flex flex-col">
            <Header />
            <div
                className={`mt-16 p-2 flex flex-col gap-6 h-full md:w-[768px] lg:w-[1024px]  md:mx-auto`}
            >
                <TimeLine withdrawTime={'7 ngày'} />
                <div className="mb-10">
                    <SearchInput
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        searchType={searchType}
                        setSearchType={setSearchType}
                        platform="shopee"
                        isLoading={isLoading}
                    />
                    {searchTerm && (
                        <SearchResult
                            productList={productList}
                            isLoading={isLoading}
                            platform="shopee"
                        />
                    )}
                </div>
                <Categories platform={'shopee'} />
                <div className="p-2">
                    <Footer />
                </div>
                <Modal isOpen={openModal}>
                    <JoinGroup onClose={() => setOpenModal(false)} />
                </Modal>
            </div>
        </div>
    );
}

export default Shopee;
