import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { faGift, faXmark } from '@fortawesome/free-solid-svg-icons';
import Header from '../../../../components/Header';
import RefUsers from './RefUsers';

function Ref() {
    const { userInfo } = useSelector((state) => state.auth);
    const [linkRef, setLinkRef] = useState();
    const [copied, setCopied] = useState(false);

    let timeOutId;
    const onCopy = () => {
        if (!linkRef) return;
        clearTimeout(timeOutId);
        copy(linkRef);
        setCopied(true);
        timeOutId = setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    useEffect(() => {
        if (!userInfo) return;
        setLinkRef(`https://chietkhau.pro?ref=${userInfo.userId}`);
    }, [userInfo]);
    return (
        <div>
            <Header />
            <div className="relative pt-16">
                {copied && (
                    <div className="absolute top-0 z-50 w-full animate-zoomIn">
                        <p className="text-center p-3 bg-primary-100 text-primary-700">
                            Sao chép thành công
                        </p>
                        <FontAwesomeIcon
                            icon={faXmark}
                            className="absolute top-1/2 right-2 -translate-y-1/2 text-gray-400"
                            onClick={() => setCopied(false)}
                        />
                    </div>
                )}
                <div className="flex flex-col justify-center items-center p-4">
                    <FontAwesomeIcon icon={faGift} className="text-2xl" />
                    <p className="font-bold">Giới thiệu Chiết Khấu Pro</p>
                    <span>Nhận ngay hoa hồng khủng</span>
                </div>
                <div className="p-4 text-sm">
                    <p className="font-semibold">Thể lệ chương trình:</p>
                    <p>
                        Khi giới thiệu người dùng thành công, bạn sẽ nhận được
                        hoa hồng trên mỗi đơn hàng mà người được giới thiệu phát
                        sinh.
                    </p>
                </div>
                <div className="p-4 text-sm flex flex-col gap-1">
                    <p className="font-semibold">Mức hoa hồng thưởng:</p>
                    <table className="w-full">
                        <tbody className="border-collapse">
                            <tr className="grid grid-cols-2">
                                <td className="border col-span-1">Shopee</td>
                                <td className="border col-span-1">5%</td>
                            </tr>
                            <tr className="grid grid-cols-2">
                                <td className="border col-span-1">Lazada</td>
                                <td className="border col-span-1">5%</td>
                            </tr>
                            <tr className="grid grid-cols-2">
                                <td className="border col-span-1">
                                    TikTok Shop
                                </td>
                                <td className="border col-span-1">5%</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        Ví dụ: Bạn giới thiệu được A, và A phát sinh 1 đơn hàng
                        có mức chiết khấu là 20.000đ từ Shopee, bạn sẽ nhận được
                        một khoản thưởng là 20.000đ * 10% = 2.000đ.
                    </p>
                    <p className="italic">
                        Chương trình áp dụng tới hết 31/01/2024
                    </p>
                </div>
                {userInfo && (
                    <div className="flex flex-col justify-center items-center gap-1 p-3">
                        <p className="font-semibold">Link giới thiệu của bạn</p>
                        <div className="flex justify-center items-center bg-primary-100 text-primary-600 w-full">
                            <p className="font-semibold">{linkRef}</p>
                            <FontAwesomeIcon
                                onClick={onCopy}
                                icon={faCopy}
                                className="text-gray-500 p-3"
                            />
                        </div>
                    </div>
                )}
                <RefUsers />
            </div>
        </div>
    );
}

export default Ref;
