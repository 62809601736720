import React from 'react';
import { rankName } from './TopOfWeek';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHelmetSafety } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const REWAR = [
    {
        rank: 7,
        coin: 500000,
        slot: 1,
    },
    {
        rank: 6,
        coin: 300000,
        slot: 1,
    },
    {
        rank: 5,
        coin: 50000,
        slot: 4,
    },
    {
        rank: 999,
        coin: 20000,
        slot: 10,
    },
];
function MonthEvent() {
    return (
        <div className="p-2 flex flex-col justify-center items-center bg-gradient-to-tr from-red-300 via-amber-200 to-yellow-400 rounded-lg shadow-lg shadow-orange-400">
            <h3 className="text-xl font-bold p-2">Thưởng Đua Top Tháng</h3>
            <div className="w-full">
                <table className="w-full">
                    <thead className="text-center">
                        <tr>
                            <th>Danh hiệu</th>
                            <th>Số lượng</th>
                            <th>Giả thưởng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {REWAR.map((el) => (
                            <tr key={el.rank} className="text-center">
                                <td>
                                    <span>{rankName(el.rank)}</span>
                                </td>
                                <td>
                                    <span>{el.slot}</span>
                                </td>
                                <td>
                                    <div className="flex gap-1 items-center justify-center text-orange-500 text-xl font-bold">
                                        <span className="text-end">
                                            {parseFloat(el.coin).toLocaleString(
                                                'vi-VN'
                                            )}
                                        </span>
                                        <div className="h-6 w-6 flex justify-center items-center text-sm bg-amber-400 rounded-full">
                                            <FontAwesomeIcon
                                                className="text-orange-500"
                                                icon={faHelmetSafety}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className=" flex justify-center w-full text-sm">
                <Link to={'game_cung_dau'} className="p-2 hover:underline">
                    Xem thêm
                </Link>
            </div>
        </div>
    );
}

export default MonthEvent;
