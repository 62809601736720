import { faHelmetSafety, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
const baseUrlImage = 'https://down-ws-vn.img.susercontent.com/';

function Question({ questions }) {
    return (
        <div className="p-2 flex flex-col gap-2">
            {questions.map((question) => (
                <div
                    key={question.questionId}
                    className="text-sm flex flex-col gap-2"
                >
                    <p className="font-semibold">{`C${question.questionIndex}. ${question.question}`}</p>
                    <p>
                        {question.answers
                            .filter((answer) => answer.isCorrectAnswer)
                            .map((el, index) => (
                                <span key={index}>
                                    {`Đáp án: ${el.answerKey}. ${el.answer}`}
                                </span>
                            ))}
                    </p>
                    <div>
                        <span className="text-sm font-semibold">
                            Danh sách trúng giải
                        </span>
                        <div>
                            {question.useranswers
                                .filter(
                                    (correctAnswer) =>
                                        correctAnswer.isCorrectAnswer
                                )
                                .map((useranswer) => (
                                    <div
                                        className="flex justify-between p-1 even:bg-slate-100"
                                        key={useranswer.username}
                                    >
                                        <div
                                            className="flex gap-1 items-center
                            "
                                        >
                                            <div className="w-8 h-8 rounded-full overflow-hidden">
                                                {useranswer.user.avatar && (
                                                    <img
                                                        className="w-full h-full object-cover"
                                                        src={
                                                            baseUrlImage +
                                                            useranswer.user
                                                                .avatar
                                                        }
                                                        alt="avatar"
                                                    />
                                                )}
                                                {!useranswer.user.avatar && (
                                                    <div className="bg-gray-300 w-full h-full flex justify-center items-center">
                                                        <FontAwesomeIcon
                                                            className="p-1"
                                                            icon={faUser}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <span>{useranswer.username}</span>
                                        </div>
                                        <div className="flex gap-1 items-center text-orange-500">
                                            <span>
                                                {parseFloat(
                                                    useranswer.claim
                                                ).toLocaleString('vi-VN')}
                                            </span>
                                            <div className="h-6 w-6 flex justify-center items-center text-sm bg-amber-400 rounded-full">
                                                <FontAwesomeIcon
                                                    className="text-orange-500"
                                                    icon={faHelmetSafety}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Question;
