import {
    faChessQueen,
    faHelmetSafety,
    faSearch,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const TODAY = new Date();
const baseUrlImage = 'https://down-ws-vn.img.susercontent.com/';
const rankName = (rank, offAnimate) => {
    if (!rank) return;
    let rankTag = (
        <span className="px-1 rounded bg-gradient-to-tr from-lime-300 to-green-600 text-white">
            Thường tại
        </span>
    );
    switch (rank) {
        case 0:
            return;
        case 1:
            break;
        case 2:
            rankTag = (
                <span className="px-1 rounded bg-gradient-to-tr from-blue-300 to-blue-500 text-white">
                    Quí nhân
                </span>
            );
            break;
        case 3:
            rankTag = (
                <div className="px-2 rounded inline-block bg-gradient-to-tr from-indigo-300 to-indigo-600 text-white relative overflow-hidden">
                    <span>Tần</span>
                    {!offAnimate && (
                        <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                    )}
                </div>
            );
            break;
        case 4:
            rankTag = (
                <div className="px-2 rounded inline-block bg-gradient-to-tr from-violet-300 to-violet-700 text-white relative overflow-hidden">
                    <span>Phi</span>
                    {!offAnimate && (
                        <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                    )}
                </div>
            );
            break;
        case 5:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-pink-300 to-pink-600 text-white relative overflow-hidden">
                    <span>Quý phi</span>
                    {!offAnimate && (
                        <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                    )}
                </div>
            );
            break;
        case 6:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-orange-300 to-orange-500 text-white relative overflow-hidden">
                    <span> Hoàng quý phi</span>
                    {!offAnimate && (
                        <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                    )}
                </div>
            );
            break;
        case 7:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-amber-300 to-amber-500 text-white relative overflow-hidden">
                    <span>
                        Hoàng hậu <FontAwesomeIcon icon={faChessQueen} />
                    </span>
                    {!offAnimate && (
                        <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                    )}
                </div>
            );
            break;
        case 999:
            rankTag = (
                <div className="px-1 rounded inline-block bg-gradient-to-tr from-red-300 via-indigo-500 to-pink-500 text-white relative overflow-hidden">
                    <span>May mắn</span>
                    {!offAnimate && (
                        <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent  animate-hightLight"></span>
                    )}
                </div>
            );
            break;
        default:
            break;
    }

    return rankTag;
};

const convertStatusPaidCoin = (status) => {
    let defaultStatus = (
        <span className="bg-green-100 text-green-500 px-1 rounded-md font-semibold border border-green-300">
            Hoàn thành
        </span>
    );
    switch (status) {
        case 1:
            defaultStatus = (
                <span className="bg-orange-100 text-orange-500 px-1 rounded-md font-semibold  border border-orange-300">
                    Đang xử lý
                </span>
            );
            break;
        default:
            break;
    }

    return defaultStatus;
};
function TopOfWeek({ topUser, username, setUsername }) {
    const [input, setInput] = useState('');
    const [showUser, setShowUser] = useState();
    let typingTimer;
    const delayActionTime = 800;
    const handleTypingDone = (e) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            setUsername(e.target.value);
        }, delayActionTime);
    };

    const onShowUser = (userId) => {
        setShowUser((prev) => {
            if (prev === userId) return undefined;
            return userId;
        });
    };

    useEffect(() => {
        if (!username) setInput('');
    }, [username]);
    return (
        <div className="p-4 text-sm">
            <div className="p-2 bg-gradient-to-tr from-emerald-300 to-emerald-500 flex flex-col gap-3 rounded-md shadow-md pb-4">
                <h3 className="text-center font-bold px-2 pt-2">
                    {`Bảng Vàng Danh Hiệu tháng ${TODAY.getMonth() + 1}`}
                </h3>
                <p className="px-1 text-center italic">
                    Bấm vào avatar để xem chi tiết lịch sử trả thưởng
                </p>
                <div className="mt-4 flex justify-end w-full">
                    <div className="relative w-full">
                        <FontAwesomeIcon
                            icon={faSearch}
                            className="absolute left-2 top-1/2 -translate-y-1/2"
                        />
                        <input
                            value={input}
                            onKeyUp={handleTypingDone}
                            onChange={(e) => setInput(e.target.value)}
                            type="text"
                            placeholder="Tìm kiếm"
                            className="p-2 rounded-md pl-8 w-full text-base focus-within:border focus-within:border-pink-300"
                            autoFocus={false}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    {topUser.map((el, index) => (
                        <div
                            onClick={() => onShowUser(el.userId)}
                            className="flex flex-col p-1 gap-1 even:bg-slate-100 odd:bg-slate-50 rounded-md"
                            key={index}
                        >
                            <div className="flex justify-between w-full">
                                <div className="flex items-center gap-2">
                                    <span className="h-full w-4 flex items-center justify-center">
                                        {index + 1}
                                    </span>
                                    <div className="flex gap-1 items-center">
                                        <div className="w-8 h-8 rounded-full overflow-hidden">
                                            {el.avatar && (
                                                <img
                                                    className="w-full h-full object-cover"
                                                    src={
                                                        baseUrlImage + el.avatar
                                                    }
                                                    alt="avatar"
                                                />
                                            )}
                                            {!el.avatar && (
                                                <div className="bg-gray-300 w-full h-full flex justify-center items-center">
                                                    <FontAwesomeIcon
                                                        className="p-1"
                                                        icon={faUser}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className=" relative">
                                            <p className="line-clamp-1">
                                                {el.username || '---'}
                                            </p>
                                            <div className="text-xs whitespace-nowrap">
                                                {rankName(el.rank)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-1 items-center text-orange-500">
                                    <span>
                                        {el.coin
                                            ? parseFloat(
                                                  el.coin
                                              ).toLocaleString('vi-VN')
                                            : '---'}
                                    </span>
                                    <div className="h-6 w-6 flex justify-center items-center text-sm bg-amber-400 rounded-full">
                                        <FontAwesomeIcon
                                            className="text-orange-500"
                                            icon={faHelmetSafety}
                                        />
                                    </div>
                                </div>
                            </div>
                            {el.paidcoins && showUser === el.userId && (
                                <div className="w-full p-1">
                                    <div className="bg-pink-200 p-1 rounded-md">
                                        <div className="flex gap-2">
                                            <span>Hình thức thanh toán:</span>
                                            <span>
                                                {el.bankInfo ? (
                                                    <span className="text-primary-600 font-semibold">
                                                        Chuyển khoản
                                                    </span>
                                                ) : (
                                                    <span className="text-orange-500 font-semibold">
                                                        Shopee Pay
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        {el.bankInfo && (
                                            <div className="flex flex-col">
                                                <span>
                                                    {
                                                        el.bankInfo?.bankName.split(
                                                            '-'
                                                        )[0]
                                                    }
                                                </span>
                                                <span>{el.accountNumber}</span>
                                            </div>
                                        )}
                                    </div>
                                    <h4>Lịch sử trả thưởng:</h4>
                                    {el.paidcoins.map((withdraw, index) => (
                                        <div
                                            key={withdraw.id}
                                            className="border-b last:border-none pb-1"
                                        >
                                            <div className="grid grid-cols-3">
                                                <span>{`#${index + 1}`}</span>
                                                <span>
                                                    {parseFloat(
                                                        withdraw.coin
                                                    ).toLocaleString('vi-VN')}
                                                </span>
                                                <span className="col-span-1 text-end">
                                                    {format(
                                                        withdraw.createTime,
                                                        'dd/MM'
                                                    )}
                                                </span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span className="text-xs">
                                                    {convertStatusPaidCoin(
                                                        withdraw.status
                                                    )}
                                                </span>
                                                <span>{withdraw.note}</span>
                                                <a
                                                    className="underline"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={`${process.env.REACT_APP_API_PUBLIC_URL}/img/game_bills/${withdraw.paidBill}`}
                                                >
                                                    Chi tiết
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <Link
                    target="_blank"
                    to={'https://forms.gle/fkRaL838ZNVMUyiv7'}
                    className="text-center p-2 bg-white rounded-md text-primary-600 font-semibold"
                >
                    QUAN TRỌNG! Cập nhật thông tin thanh toán ở đây{' '}
                    <span className="underline">
                        https://forms.gle/fkRaL838ZNVMUyiv7
                    </span>
                </Link>
            </div>
        </div>
    );
}

export default TopOfWeek;

export { rankName };
