import React from 'react';
import { Link } from 'react-router-dom';

function VideoGuide() {
    return (
        <div className="p-2 mt-2 w-full flex justify-center items-center">
            <ul className="list-decimal p-6 space-y-2 text-sm bg-white rounded-lg shadow-sm shadow-pink-500">
                <li>Khuyến khích thêm 6 sản phẩm trong cùng 1 video</li>
                <li>
                    Nếu bị lỗi code 500, thử tạo lại vài lần là được. Quá 5 phút
                    không được -{'>'} bay acc
                </li>
                <li>
                    Nếu vào video ko có giỏ thì là do Shopee quét, tạo lại và
                    vào thật nhanh nhé
                </li>
                <li>
                    Vào nhóm Zalo bên dưới đợi thông báo mở gắn video từ Admin.
                    Khi nào bài đăng có link dẫn tới đây là auto gắn được video{' '}
                    <Link
                        className="font-bold text-primary-600 underline"
                        to={'https://zalo.me/g/iejprn711'}
                        target="_blank"
                    >
                        https://zalo.me/g/iejprn711
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default VideoGuide;
