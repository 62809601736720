import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/bundle';
import { Link } from 'react-router-dom';

const BigBanner = ({ bannerList }) => {
    return (
        <Swiper
            style={{
                '--swiper-pagination-color': '#FFF',
            }}
            modules={[Pagination, Autoplay]}
            className="h-full shadow-md rounded-lg overflow-hidden"
            spaceBetween={50}
            slidesPerView={1}
            autoplay
            pagination={{ clickable: true }}
        >
            {bannerList.map((el, index) => (
                <SwiperSlide key={index} className="shadow-">
                    <Link to={el.url}>
                        <img
                            crossOrigin="anonymous"
                            className="object-cover h-full w-full"
                            src={el.imageUrl}
                            alt={el.merchant}
                        />
                    </Link>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default BigBanner;
