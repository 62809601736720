import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import redirectReducer from './redirectSlide';
import refReducer from './refSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        redirect: redirectReducer,
        ref: refReducer,
    },
});

export default store;
