import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Bell from './BellNoti';

function Header() {
    const { isLogined, userInfo } = useSelector((state) => state.auth);
    return (
        <header className="h-16 fixed top-0 left-0 right-0 w-full border-b shadow-sm bg-white z-50">
            <div className="h-full flex items-center justify-between py-2 px-4 md:w-[768px] lg:w-[1024px] mx-auto">
                <Link
                    to={'/'}
                    className="h-full flex gap-2 items-center font-semibold"
                >
                    <div className="h-full rounded-lg hover:bg-slate-100">
                        <img
                            className="h-full object-cover"
                            src="/img/brand-logo/logo-chietkhaupro-500x160.svg"
                            alt="logo-chietkhaupro"
                        />
                    </div>
                </Link>
                <div className="h-2/3">
                    {isLogined && (
                        <div className="flex h-full justify-between items-center gap-3">
                            <Bell notiList={userInfo.notifications} />
                            <Link
                                to={'/user-info'}
                                className="rounded-full h-full overflow-hidden aspect-square flex items-center justify-center hover:border border-primary-400"
                            >
                                {userInfo.avatar && (
                                    <img
                                        className="object-cover h-full"
                                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${userInfo.avatar}`}
                                        alt="user-avatar"
                                    />
                                )}
                                {!userInfo.avatar && (
                                    <span className="flex justify-center items-center bg-primary-200 w-full h-full">
                                        <FontAwesomeIcon icon={faUser} />
                                    </span>
                                )}
                            </Link>
                        </div>
                    )}
                    {!isLogined && (
                        <div className="flex items-center w-full h-full bg-slate-950 py-4 px-2 text-white text-sm rounded-lg">
                            <Link to="/login">Đăng nhập</Link>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
