import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import shopeexuApi from '../../../api/shopeexuApi';
import { format, formatDistanceStrict } from 'date-fns';
import { vi } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faClock,
    faCoins,
    faSpinner,
    faWarning,
} from '@fortawesome/free-solid-svg-icons';
import JoinZalo from '../../../components/Chatbox/JoinZalo';
import linkShopeeAffGenerator from '../../../utils/generateLinkAffShopee';
import { Link } from 'react-router-dom';
import shortLinkApi from '../../../api/shortLinkApi';
import shopeApi from '../../../api/shopeeApi';

const clickAffLink = (url) => {
    window.location.href = url;
};
const affIdList = [
    // { sub_id: 'viet3', aff_id: 17369970114 },
    // { sub_id: 'viet4', aff_id: 17389020123 },
    { sub_id: 'viet6', aff_id: 17358480133 },
    // { sub_id: 'viet7', aff_id: 17342720157 },
    { sub_id: 'viet8', aff_id: 17326820122 },
    // { sub_id: 'viet9', aff_id: 17304830137 },
    { sub_id: 'viet10', aff_id: 17381590117 },
    { sub_id: 'viet11', aff_id: 17351000129 },
    { sub_id: 'chietkhaupro', aff_id: 17385060127 },
    { sub_id: 'viet12', aff_id: 17342210178 },
    { sub_id: 'hang', aff_id: 17384610153 },
    { sub_id: 'ngannhung', aff_id: 17321900161 },
];

function ShopeeXuV2() {
    const [spinnerList, setSpinnerList] = useState([]);
    const [realTime, setRealTime] = useState(Date.now());
    const [onGetLink, setOnGetLink] = useState(false);
    const fetchSpinner = async () => {
        try {
            const time = Date.now() + 3000;
            const { data } = await shopeexuApi.getAllSpinner(time);
            setSpinnerList(data.data.allSpinner);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSpinner();
        // fetchHost();
    }, []);
    useEffect(() => {
        const counter = setInterval(() => {
            const now = Date.now();
            setRealTime(now);
            setSpinnerList((prev) =>
                prev.filter((el) => new Date(el.startTime) > now)
            );
        }, 1000);

        return () => {
            clearInterval(counter);
        };
    });

    const onGoToHost = async (link) => {
        const newAffLink = linkShopeeAffGenerator(link, affIdList);
        console.log(newAffLink);
        clickAffLink(newAffLink);
    };

    const onGoToShortLink = async (link) => {
        setOnGetLink(true);
        try {
            const { data } = await shopeApi.getLinkAffVideo(link);
            const linkAff = data.linkAff.batchCustomLink[0].shortLink;
            console.log(linkAff);
            clickAffLink(linkAff);
        } catch (error) {
            console.log(error);
        }
        setOnGetLink(false);
    };
    return (
        <div className="h-dvh flex flex-col">
            <Header />
            <div className="mt-16 p-2 flex flex-col gap-6 h-full md:w-[768px] lg:w-[1024px]  md:mx-auto">
                <div>
                    <img
                        className="w-full object-cover"
                        src="/img/banner/vongquayshopee.jpeg"
                        alt="vongquayshopee"
                    />
                </div>
                <Link
                    target="_blank"
                    to={'https://forms.gle/KpEGE17pfkUqeMyd9'}
                    className="px-2 py-3 flex flex-col gap-2  items-center justify-between bg-amber-500"
                >
                    <div className="flex gap-2 items-center justify-center">
                        <FontAwesomeIcon icon={faWarning} />
                        <span> Gặp Shop mất dạy tạo vòng quay ảo?</span>
                    </div>
                    <div className="flex justify-center items-center">
                        <span>
                            <strong>Report ngay</strong>
                        </span>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </Link>
                <div className="w-full flex flex-col gap-2">
                    {spinnerList.map((el, index) => (
                        <div
                            key={el.drawId}
                            className="bg-white rounded-md p-1 shadow-lg border"
                        >
                            <div className="w-full grid grid-cols-3 items-center border-b text-sm">
                                <div className="col-span-1 flex flex-col">
                                    <span>{`#${index + 1}`}</span>
                                    <span className="line-clamp-1">
                                        {el.shopName || el.userName}
                                    </span>
                                </div>
                                <div className="col-span-1 flex flex-col justify-center items-center">
                                    <span className="text-xl font-bold text-amber-400 flex items-center gap-1 shadow-lg border px-2 rounded-lg">
                                        {parseFloat(
                                            el.maxcoin
                                        ).toLocaleString()}
                                        <FontAwesomeIcon icon={faCoins} />
                                    </span>
                                    <span>{`SL: ${el.slot}`}</span>
                                </div>
                                <div className="col-span-1 flex justify-end">
                                    <button
                                        onClick={() =>
                                            onGoToShortLink(
                                                `https://shopee.vn/shop/${el.shopId}`
                                            )
                                        }
                                        className=" bg-primary-500 text-white rounded-md h-full w-20 p-1 disabled:opacity-60 disabled:cursor-not-allowed"
                                    >
                                        {onGetLink ? (
                                            <FontAwesomeIcon
                                                icon={faSpinner}
                                                className="animate-spin"
                                            />
                                        ) : (
                                            <span className="flex justify-center items-center">
                                                Zô ngay{' '}
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                />
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className="text-sm flex justify-end items-center gap-2">
                                <span>{`Bắt đầu lúc: ${format(
                                    new Date(el.startTime),
                                    'HH:mm:ss'
                                )}`}</span>
                                <div className="flex gap-1 items-center">
                                    <FontAwesomeIcon icon={faClock} />
                                    <span className="font-semibold">
                                        {formatDistanceStrict(
                                            new Date(el.startTime),
                                            realTime,
                                            {
                                                locale: vi,
                                            }
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <JoinZalo linkZalo={'https://zalo.me/g/nuzsyb224'} />
                <div className="pb-12"></div>
            </div>
        </div>
    );
}

export default ShopeeXuV2;
