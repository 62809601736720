import React, { useEffect, useState } from 'react';
import { faHelmetSafety } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Question from './Question';
import gameApi from '../../api/gameApi';
import GameType2 from './GameType2';
import GameType3 from './GameType3';
// import Winner from './Winner';

function GameCard({ game }) {
    const { gameType, gameId } = game;
    const [userAnswers, setUsersAnswers] = useState([]);
    const fetchUserAnswers = async (gameId) => {
        const { data } = await gameApi.getUserAnswers(gameId);
        setUsersAnswers(data.userAnswers);
    };

    // const winner = userAnswers.filter((user) => {
    //     if (game.status === 2) {
    //         return (
    //             game.questions.length > 0 &&
    //             user.useranswers.length === game.questions.length &&
    //             user.useranswers.every((answer) => answer.isCorrectAnswer)
    //         );
    //     }
    //     if (game.status === 3) {
    //         return (
    //             user.useranswers.length === game.requiredAnswer &&
    //             user.useranswers.every((answer) => answer.isCorrectAnswer)
    //         );
    //     }
    //     return false;
    // });
    useEffect(() => {
        fetchUserAnswers(gameId);
    }, [gameType, gameId]);
    return (
        <div className="bg-white shadow-md shadow-pink-200 text-sm flex flex-col gap-3">
            <h4 className="text-center p-2 bg-gradient-to-tr  from-red-300 to-red-600 text-white rounded-t-md font-semibold border-b shadow-md">
                {game.gameName}
            </h4>
            <p className="p-2">{game.describe}</p>
            <div className="p-2 flex gap-2 items-center">
                <span>Tiền thưởng:</span>
                <div className="flex gap-1 items-center text-orange-500 text-xl font-bold">
                    <span>
                        {parseFloat(game.reward).toLocaleString('vi-VN')}
                    </span>
                    <div className="h-6 w-6 flex justify-center items-center text-sm bg-amber-400 rounded-full">
                        <FontAwesomeIcon
                            className="text-orange-500"
                            icon={faHelmetSafety}
                        />
                    </div>
                </div>
            </div>
            {gameType === 1 && (
                <div>
                    {/* <Winner game={game} winner={winner} /> */}
                    <Question questions={game.questions} />
                </div>
            )}
            {gameType === 2 && (
                <GameType2 userAnswers={userAnswers} game={game} />
            )}
            {gameType === 3 && (
                <GameType3 userAnswers={userAnswers} game={game} />
            )}
            {game.status === 2 && (
                <div className="flex justify-end">
                    <Link
                        to={game.link}
                        target="_blank"
                        className="p-2 bg-primary-600 text-white rounded-tl-lg shadow-sm"
                    >
                        Tham gia
                    </Link>
                </div>
            )}
        </div>
    );
}

export default GameCard;
