import { faSpinner, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import Select from 'react-select';

function SearchInput({
    searchTerm,
    setSearchTerm,
    isLoading,
    searchType,
    setSearchType,
    platform,
    selectOptions,
}) {
    const options = [
        {
            value: 'link',
            label: 'Link',
        },
        {
            value: 'product',
            label: 'Tên sản phẩm',
        },
    ];

    const inputRef = useRef();
    let platformUrl;
    switch (platform) {
        case 'shopee':
            platformUrl = 'img/brand-logo/logo-shopee-200.png';
            break;
        case 'lazada':
            platformUrl = 'img/brand-logo/logo-lazada-200.png';
            break;
        case 'tiktok':
            platformUrl = 'img/brand-logo/logo-tiktok-shop-200.png';
            break;
        default:
            platformUrl = '';
            break;
    }
    let typingTimer;
    const delayActionTime = 500;
    const handleTypingDone = (e) => {
        clearTimeout(typingTimer);
        if (e.target.value) {
            typingTimer = setTimeout(() => {
                setSearchTerm(e.target.value.trim());
            }, delayActionTime);
        } else {
            setSearchTerm('');
        }
    };

    const handleOnPaste = (e) => {
        const value = e.clipboardData.getData('text');
        setSearchTerm(value.trim());
    };

    const handleClearInput = () => {
        setSearchTerm('');
        inputRef.current.value = '';
        inputRef.current.focus();
    };

    const handleSelectSearchType = ({ value }) => {
        setSearchType(value);
    };

    const setPlacehodler = (type) => {
        switch (type) {
            case 'link':
                return 'Nhập link sản phẩm';
            case 'shopname':
                return 'Nhập tên Shop';
            case 'product':
                return 'Nhập tên sản phẩm';
            default:
                break;
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-1 justify-between rounded-lg shadow-lg bg-white">
                <div className="relative flex-1">
                    <input
                        ref={inputRef}
                        onPaste={handleOnPaste}
                        onKeyUp={handleTypingDone}
                        className="h-full py-4 pr-8 pl-4 w-full rounded-lg focus:shadow focus:shadow-primary-200 focus:outline-[1px] focus:outline-primary-300"
                        type="text"
                        placeholder={setPlacehodler(searchType)}
                    />
                    {isLoading && (
                        <span className="absolute top-1/2 right-2 -translate-y-1/2">
                            <FontAwesomeIcon
                                icon={faSpinner}
                                className="animate-spin"
                            />
                        </span>
                    )}
                    {searchTerm && !isLoading && (
                        <FontAwesomeIcon
                            onClick={handleClearInput}
                            className="absolute p-2 top-1/2 right-0 -translate-y-1/2 text-gray-400 hover:text-gray-900"
                            icon={faXmarkCircle}
                        />
                    )}
                </div>
                <div className="relative w-36">
                    <div className="absolute w-full top-0 bottom-0 z-20">
                        <Select
                            NoOptionsMessage={() => 'Trống'}
                            onChange={handleSelectSearchType}
                            options={selectOptions || options}
                            defaultValue={{
                                value: 'link',
                                label: 'Link',
                            }}
                            components={{
                                IndicatorSeparator: false,
                            }}
                            styles={{
                                control: (baseStyle, state) => ({
                                    ...baseStyle,
                                    height: '100%',
                                    border: 'none',
                                    outline: 'none',
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                }),
                                container: (baseStyle, state) => ({
                                    ...baseStyle,
                                    height: '100%',
                                    outline: 'none',
                                    border: 'none',
                                }),
                                option: (baseStyle, state) => ({
                                    ...baseStyle,
                                    backgroundColor:
                                        state.isSelected && '#ec4899',
                                    ':hover': {
                                        backgroundColor:
                                            !state.isSelected && '#fce7f3',
                                    },
                                }),
                            }}
                            placeholder="Chọn"
                        />
                    </div>
                    <span className="absolute border left-0 z-20 h-2/3 top-1/2 -translate-y-1/2"></span>
                </div>
            </div>
            <div className="flex justify-center gap-1 items-center h-10">
                <span className="text-sm text-gray-500">Nền tảng hỗ trợ:</span>
                <div className="h-full aspect-square overflow-auto">
                    <img
                        className="h-full object-cover"
                        src={`../${platformUrl}`}
                        alt={platform}
                    />
                </div>
            </div>
        </div>
    );
}

export default SearchInput;
