const videosFilterPostId = (videos) => {
    const videosFilter = videos.reduce((acc, cur) => {
        if (acc[cur.postId]) {
            acc[cur.postId].push(cur);
            return acc;
        }
        acc[cur.postId] = [cur];
        return acc;
    }, {});
    return videosFilter;
};

export default videosFilterPostId;
