import React from 'react';
import GameCard from './GameCard';

function EndGame({ endGame }) {
    return (
        <div className="flex flex-col gap-4">
            {endGame.map((el) => (
                <GameCard key={el.gameId} game={el} />
            ))}
        </div>
    );
}

export default EndGame;
