import React, { useEffect, useRef, useState } from 'react';
import shopeeLiveApi from '../../../api/shopeeLiveApi';
import Header from '../../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowDown,
    faSearch,
    faSpinner,
    faTshirt,
    faTv,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import SearchResult from './SearchResult';
import LiveInfo from './LiveInfo';
import OrderLive from './OrderLive';
import { useSelector } from 'react-redux';
import InstallGooogleAds from '../../../components/GoogleAdsense/InstallGooogleAds';

function ShopeeLiveV2() {
    const { isLogined, userInfo } = useSelector((state) => state.auth);
    console.log(userInfo);
    const [goliveSession, setGoliveSession] = useState([]);
    const [pendingLiveSession, setPendingLiveSession] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [userProducts, setUserProducts] = useState([]);
    const serachInputRef = useRef();
    let typingTimer;
    const delayActionTime = 500;
    const handleTypingDone = (e) => {
        clearTimeout(typingTimer);
        if (e.target.value) {
            typingTimer = setTimeout(() => {
                setSearchTerm(e.target.value.trim());
            }, delayActionTime);
        } else {
            setSearchTerm('');
            setSearchResult([]);
        }
    };

    const handleOnPasteSearch = (e) => {
        const value = e.clipboardData.getData('text');
        setSearchTerm(value.trim());
    };

    const reloadPendingLiveSession = async () => {
        try {
            const { data } = await shopeeLiveApi.getSessionV2('status=1');
            setPendingLiveSession(data.liveSessions);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchGoliveSession = async () => {
            try {
                const { data } = await shopeeLiveApi.getSessionV2('status=2');
                setGoliveSession(data.liveSessions);
                if (data.liveSessions.length > 0) {
                    setSearchResult(
                        data.liveSessions[0].liveproducts.splice(0, 4)
                    );
                }
                if (isLogined) {
                    const products = data.liveSessions[0].liveproducts.filter(
                        (el) => el.userId === userInfo.userId
                    );
                    setUserProducts(products);
                }
            } catch (error) {
                console.log(error);
            }
        };
        const fetchPendingLiveSession = async () => {
            try {
                const { data } = await shopeeLiveApi.getSessionV2('status=1');
                setPendingLiveSession(data.liveSessions);
            } catch (error) {
                console.log(error);
            }
        };
        fetchGoliveSession();
        fetchPendingLiveSession();
    }, [isLogined, userInfo]);

    useEffect(() => {
        if (!goliveSession.length > 0 || !searchTerm) return;
        const fetchLiveProduct = async () => {
            setSearchLoading(true);
            try {
                const { data } = await shopeeLiveApi.searchLiveProductsV2(
                    searchTerm,
                    goliveSession[0].id
                );
                setSearchResult(data.liveProducts || []);
            } catch (error) {
                console.log(error);
            }
            setSearchLoading(false);
        };
        fetchLiveProduct();
    }, [searchTerm, goliveSession]);
    return (
        <div className="flex flex-col justify-center">
            <Header />
            <div className="p-4 pt-20">
                <LiveInfo
                    goliveSession={goliveSession}
                    pendingLives={pendingLiveSession}
                />
                {isLogined && goliveSession.length > 0 && (
                    <div className="mt-4">
                        <span className="font-semibold">Sản phẩm của bạn</span>
                        <SearchResult liveProducts={userProducts} />
                        {userProducts.length === 0 && (
                            <div className=" flex flex-col justify-center items-center text-gray-500">
                                <FontAwesomeIcon icon={faTshirt} />
                                <span className="text-sm">
                                    Không có sản phẩm nào trong phiên này.
                                </span>
                            </div>
                        )}
                    </div>
                )}
                <div className="mt-6">
                    <span className="font-semibold">
                        Tìm STT sản phẩm trong LIVE
                    </span>
                    <div className="bg-white p-2 rounded-md shadow-lg border focus-within:border-primary-400 flex items-center relative">
                        <input
                            type="text"
                            name=""
                            id=""
                            className="bg-transparent flex-1 p-1"
                            placeholder="Tên sản phẩm hoặc Link"
                            ref={serachInputRef}
                            onKeyUp={handleTypingDone}
                            onPaste={handleOnPasteSearch}
                        />
                        {searchLoading ? (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                className="text-gray-400 px-1 animate-spin"
                            />
                        ) : searchTerm ? (
                            <FontAwesomeIcon
                                onClick={() => {
                                    setSearchTerm('');
                                    serachInputRef.current.value = '';
                                    setSearchResult([]);
                                }}
                                icon={faXmarkCircle}
                                className="text-gray-400 p-1"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faSearch}
                                className="text-gray-400 px-1"
                            />
                        )}
                    </div>
                    {searchResult.length > 0 && (
                        <div className="w-full mt-4">
                            <SearchResult liveProducts={searchResult} />
                        </div>
                    )}
                </div>
                {goliveSession.length === 0 && (
                    <div className="p-4">
                        <div className=" flex flex-col justify-center items-center text-slate-500">
                            <FontAwesomeIcon icon={faTv} className="text-xl" />
                            <span>Hiện chưa có phiên LIVE nào</span>
                        </div>
                    </div>
                )}
                <div className="my-6 border"></div>
                <div className="flex flex-col items-center justify-center">
                    <span className="text-xl font-semibold">
                        Đặt Lịch LIVE ngay nè!
                    </span>
                    <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-2xl text-slate-800 animate-bounce mt-2"
                    />
                </div>
                <OrderLive
                    pendingLives={pendingLiveSession}
                    reloadPendingLive={reloadPendingLiveSession}
                />
            </div>
            <div>
                <InstallGooogleAds />
            </div>
        </div>
    );
}

export default ShopeeLiveV2;
