import React from 'react';
import GameCard from './GameCard';

function OnAirGame({ onLiveGame }) {
    return (
        <div className="flex flex-col gap-4">
            {onLiveGame.map((game) => (
                <GameCard key={game.gameId} game={game} />
            ))}
        </div>
    );
}

export default OnAirGame;
