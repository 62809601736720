import React from 'react';
import { rankName } from './TopOfWeek';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHelmetSafety } from '@fortawesome/free-solid-svg-icons';

const REWAR = [
    {
        rank: 6,
        coin: 340584,
        slot: 'lizenaa',
    },
    {
        rank: 5,
        coin: 86453,
        slot: '_van_nhac',
    },
    {
        rank: 5,
        coin: 86297,
        slot: 'xwl3na9l2a',
    },
    {
        rank: 5,
        coin: 83512,
        slot: 'lantran2318',
    },
    {
        rank: 5,
        coin: 82747,
        slot: 'be_tien_tien',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'kanghibeung',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'duchoavp',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'b1lf72561p',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'cobehattieu01',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'nguyenvan_201293',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'kimhong1971',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'vanthihoanchau',
    },
    {
        rank: 999,
        coin: 20000,
        slot: '1975dieu',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'phamduong33',
    },
    {
        rank: 999,
        coin: 20000,
        slot: 'dieuhuongqn',
    },
];
function TopUserWinMonth() {
    return (
        <div className="p-2 flex flex-col justify-center items-center bg-gradient-to-tr from-red-300 via-amber-200 to-yellow-400 rounded-lg shadow-lg shadow-orange-400">
            <h3 className="text-xl font-bold p-2">Bảng vàng Tháng 5</h3>
            <div className="w-full">
                <table className="w-full text-sm">
                    <thead className="text-center">
                        <tr>
                            <th>Danh hiệu</th>
                            <th>Tên</th>
                            <th>Giả thưởng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {REWAR.map((el) => (
                            <tr key={el.rank} className="text-center">
                                <td>
                                    <span>{rankName(el.rank, true)}</span>
                                </td>
                                <td>
                                    <span>{el.slot}</span>
                                </td>
                                <td>
                                    <div className="flex gap-1 items-center justify-center text-orange-500 text-base font-bold">
                                        <span className="text-end">
                                            {parseFloat(el.coin).toLocaleString(
                                                'vi-VN'
                                            )}
                                        </span>
                                        <div className="h-6 w-6 flex justify-center items-center text-sm bg-amber-400 rounded-full">
                                            <FontAwesomeIcon
                                                className="text-orange-500"
                                                icon={faHelmetSafety}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TopUserWinMonth;
