import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

function Bell({ notiList }) {
    const notiUnread = notiList?.filter((el) => el.unread);
    return (
        <Link to={'/notification'} className="text-xl cursor-pointer relative">
            <div className="relative">
                <FontAwesomeIcon icon={faBell} className="hover:opacity-70" />
                {notiList && notiUnread.length > 0 && (
                    <span className="absolute top-[-3px] left-2 bg-red-500 text-white px-1 rounded-lg text-xs">
                        {notiUnread.length}
                    </span>
                )}
            </div>
        </Link>
    );
}

export default Bell;
