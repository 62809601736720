import React from 'react';
import { Link } from 'react-router-dom';

function Utilitys() {
    return (
        <div>
            <p className="font-semibold">Tiện ích</p>
            <div className="grid grid-cols-3 gap-2 mt-2 select-none">
                <Link
                    to={'https://shopeexu-nextjs.vercel.app/'}
                    className="border flex flex-col hover:shadow-lg rounded-lg overflow-hidden bg-white shadow-lg"
                >
                    <div className="w-full aspect-square rounded-lg overflow-hidden">
                        <img
                            className="object-cover h-full w-full"
                            src="/img/banner/luckey_wheel.jpg"
                            alt="shopeelive-logo"
                        />
                    </div>
                    <span className="text-center font-semibold p-1 hover:border text-sm">
                        Săn xu Shopee
                    </span>
                </Link>
                <Link
                    to={'/minigame'}
                    className="border flex flex-col hover:shadow-lg rounded-lg overflow-hidden bg-white shadow-lg"
                >
                    <div className="w-full aspect-square rounded-lg overflow-hidden">
                        <img
                            className="object-cover h-full w-full"
                            src="/img/banner/minigame.jpeg"
                            alt="shopeelive-logo"
                        />
                    </div>
                    <span className="text-center font-semibold p-1 hover:border text-sm">
                        Minigame Shopee
                    </span>
                </Link>
            </div>
        </div>
    );
}

export default Utilitys;
