import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TopToast({ copied, setCopied }) {
    return (
        <div
            className={`absolute top-0 w-full h-full z-50 animate-zoomIn text-center bg-primary-100 text-primary-700 flex justify-center items-center ${
                !copied && 'hidden'
            }`}
        >
            <span>Sao chép thành công</span>
            <FontAwesomeIcon
                icon={faXmark}
                className="absolute top-1/2 right-2 -translate-y-1/2 text-gray-400"
                onClick={() => setCopied(false)}
            />
        </div>
    );
}

export default TopToast;
