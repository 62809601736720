import React from 'react';
import Header from '../../components/Header';
import { rankName } from './TopOfWeek';
import { Link } from 'react-router-dom';

const RANK = [
    {
        rank: 1,
        min: 1000,
        limit: 0,
    },
    {
        rank: 2,
        min: 5000,
        limit: 0,
    },
    {
        rank: 3,
        min: 10000,
        limit: 0,
    },
    {
        rank: 4,
        min: 15000,
        limit: 0,
    },
    {
        rank: 5,
        min: 20000,
        limit: 4,
    },
    {
        rank: 6,
        min: 30000,
        limit: 1,
    },
    {
        rank: 7,
        min: 50000,
        limit: 1,
    },
];
function GameCungDau() {
    return (
        <div className="p-4">
            <Header />
            <div className="flex flex-col gap-3 justify-between h-full md:w-[768px] lg:w-[1024px] md:mx-auto pt-16 pb-10">
                <div>
                    <h3 className="font-bold">
                        Chi tiết sự kiện: Đua Top Tháng
                    </h3>
                    <p>Thời gian diễn ra: 01/05/2024 - 31/05/2024</p>
                </div>
                <p>
                    Bằng việc trả lời các câu hỏi trong buổi Livestream, các bạn
                    sẽ nhận được số xu tương ứng mỗi câu trả lời đúng. Số xu này
                    sẽ cộng dồn trong tháng, khi đạt được mốc xu nhất định, bạn
                    sẽ nhận được danh hiệu tương ứng dưới đây:
                </p>
                <div className="flex justify-center bg-white py-3 rounded-md">
                    <table className="w-full text-sm">
                        <thead>
                            <tr>
                                <th>Danh hiệu</th>
                                <th>Số lượng</th>
                                <th>Mốc</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {RANK.reverse().map((el) => (
                                <tr key={el.rank} className="even:bg-slate-200">
                                    <td>{rankName(el.rank)}</td>
                                    <td>
                                        {el.limit === 0
                                            ? 'Không giới hạn'
                                            : el.limit}
                                    </td>
                                    <td>
                                        {parseFloat(el.min).toLocaleString(
                                            'vi-VN'
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <p>
                    Đối với 1 số danh hiệu giới hạn, sẽ xếp hạng theo thứ tự từ
                    cao xuống thấp. Ví dụ: A có số xu là 60.000, B có số xu là
                    58.000, cả 2 đều đạt đủ điều kiện danh hiệu{' '}
                    <span className="text-sm">{rankName(7)}</span>, nhưng vì A
                    có xu cao hơn, nên A{' '}
                    <span className="text-sm">{rankName(7)}</span> còn B
                    <span className="text-sm">{rankName(6)}</span>
                </p>
                <div className="bg-white p-2 rounded-md shadow-sm border flex flex-col gap-2">
                    <h4 className="font-bold py-2">Trao thưởng</h4>
                    <div className="">
                        <span className="font-semibold text-primary-600">
                            1.Tiền thưởng:
                        </span>
                        <p>
                            Bao gồm thưởng danh hiệu + số xu bạn có được trong
                            tháng
                        </p>
                    </div>
                    <div className="">
                        <span className="font-semibold text-primary-600">
                            Thời gian trao thưởng:
                        </span>
                        <p>
                            Phần thưởng sẽ được trao vào ngày 1, ngày 2, ngày 3
                            của tháng kế tiếp.
                        </p>
                    </div>
                    <div>
                        <span className="font-semibold text-primary-600">
                            Hình thức trao thưởng:
                        </span>
                        <div>
                            <div>
                                <span className="font-semibold">
                                    Ưu tiên Shopee pay:
                                </span>
                                <span>
                                    Nếu bạn có Shopee Pay, tiền thưởng sẽ được
                                    trao tự động
                                </span>
                            </div>
                            <div>
                                <span className="font-semibold">
                                    Hình thức khác:
                                </span>
                                <div className="flex gap-1">
                                    <span>
                                        Nếu bạn không có Shopee Pay, vui lòng
                                        cập nhật thông tin ngân hàng theo form{' '}
                                        <Link
                                            to={
                                                'https://forms.gle/fkRaL838ZNVMUyiv7'
                                            }
                                            target="_blank"
                                            className="font-bold underline"
                                        >
                                            TẠI ĐÂY
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <span className="font-semibold text-orange-400">
                            QUAN TRỌNG!
                        </span>
                        <p>
                            Tiền thưởng sẽ chỉ được trao trong 3 ngày 1,2,3 của
                            tháng kế tiếp. Nếu sau 3 ngày Admin không thể liên
                            lạc được với bạn, toàn bộ dữ liệu xu sẽ được reset
                            về 0 để tiếp tục sự kiện tiếp theo. Vì vậy để đảm
                            bảo quyền lợi, hãy liên hệ với Admin ngay để được
                            trả thưởng. Admin xin trân thành cảm ơn.
                        </p>
                        <p className="italic text-primary-600">
                            Lưu ý: Số tiền trả thưởng từ 5.000xu trở lên, nếu
                            bạn chưa đủ 5.000 xu? Đừng lo, xu sẽ được cộng dồn
                            sang tháng tiếp theo nhé
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GameCungDau;
