import React, { useEffect, useState } from 'react';
import authApi from '../../api/authApi';
import formattedMoney from '../../utils/formatMoney';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

function TopOfMonth() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const { data } = await authApi.topOfMonth();
                setUsers(
                    data.orderByUsers
                        .filter((el) => el.userId && el.userId !== '')
                        .splice(0, 10)
                );
            } catch (error) {
                console.log(error);
            }
        };
        fetchUsers();
    }, []);
    return (
        <div className="bg-gradient-to-tr from-indigo-200 to-pink-500 rounded-lg shadow-lg drop-shadow-lg backdrop-blur-lg">
            <div className="w-full p-6 rounded-lg bg-gradient-to-br from-white/70 to-white/30">
                <label className="font-bold" htmlFor="">
                    {`Bảng Vàng Tháng ${new Date().getMonth() + 1}`}
                </label>
                <table className="w-full text-right">
                    <thead>
                        <tr>
                            <td>Top</td>
                            <td>User ID</td>
                            <td>Hoàn tiền</td>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((el, index) => (
                            <tr key={index}>
                                <td className={`w-10 h-10 relative `}>
                                    <p
                                        className={`${
                                            index === 0 &&
                                            'font-bold text-2xl text-amber-400'
                                        } ${
                                            index === 1 &&
                                            'font-bold text-2xl text-gray-500'
                                        } ${
                                            index === 2 &&
                                            'font-bold text-2xl text-orange-700'
                                        }`}
                                    >
                                        {index + 1}
                                    </p>
                                    {index === 0 && (
                                        <FontAwesomeIcon
                                            icon={faCrown}
                                            className="absolute top-0 right-100 rotate-45 text-amber-400"
                                        />
                                    )}
                                </td>
                                <td>{el.userId}</td>
                                <td>
                                    {formattedMoney.format(
                                        el.totalUserCommission
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TopOfMonth;
