import React, { useEffect } from 'react';
import Header from '../../components/Header';
import BigBanner from '../../components/Slider/BigBaner';
import Footer from '../../components/Footer';
import EcommerceBrand from './EcommerceBrand';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { refActions } from '../../store/refSlice';
import TopOfMonth from './TopOfMonth';
import JoinZalo from '../../components/Chatbox/JoinZalo';
import Utilitys from './Utilitys';
import JoinGroupMain from '../../components/Chatbox/JoinGroupMain';

function Home() {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const bannerList = [
        // {
        //     merchant: 'minigame',
        //     imageUrl: `${process.env.REACT_APP_API_PUBLIC_URL}/img/banner/cungdaubanner.png`,
        //     url: 'minigame',
        // },
        {
            merchant: 'chietkhaupro',
            imageUrl: `${process.env.REACT_APP_API_PUBLIC_URL}/img/banner/withdrawbanner.jpg`,
            url: '',
        },
        // {
        //     merchant: 'lazada',
        //     imageUrl: `${process.env.REACT_APP_API_PUBLIC_URL}/img/banner/lazada-4-4-banner.png`,
        // },
        // {
        //     merchant: 'tiktok',
        //     imageUrl: `${process.env.REACT_APP_API_PUBLIC_URL}/img/banner/tiktok-4-4-banner.png`,
        // },
        // {
        //     merchant: 'shopee',
        //     imageUrl: `${process.env.REACT_APP_API_PUBLIC_URL}/img/banner/shopee-4-4-banner.png`,
        // },
    ];

    useEffect(() => {
        if (!params.get('ref')) return;
        document.cookie = `ref=${params.get('ref')}`;
        dispatch(refActions.setRef(params.get('ref')));
    }, [params, dispatch]);
    return (
        <div className="h-dvh flex flex-col">
            <Header />
            <div className="flex flex-col justify-between h-full md:w-[768px] lg:w-[1024px] md:mx-auto ">
                <div className="pt-20 flex flex-col gap-4 px-4 ">
                    <div className="h-44 sm:h-60 md:h-80 lg:h-[420px]">
                        <BigBanner bannerList={bannerList} />
                    </div>
                    <EcommerceBrand />
                    <JoinGroupMain
                        title={'Nhập hội săn sale cùng tui nhaaa'}
                        zaloUrl={'https://zalo.me/g/uxtvkq172'}
                    />
                    <Utilitys />
                    <TopOfMonth />
                </div>
                <div className="p-2">
                    <Footer />
                </div>
            </div>
            <JoinZalo linkZalo={'https://zalo.me/g/uxtvkq172'} />
        </div>
    );
}

export default Home;
