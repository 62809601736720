import React, { useEffect, useState } from 'react';
import gameApi from '../../api/gameApi';
import Header from '../../components/Header';
import EndGame from './EndGame';
import PendingGame from './PendingGame';
import OnAirGame from './OnAriGame';
import TopOfWeek from './TopOfWeek';
import JoinZalo from '../../components/Chatbox/JoinZalo';
import MonthEvent from './MonthEvent';
import TopUserWinMonth from './TopUserWinMonth';

function Game() {
    const [endGameList, setEndGameList] = useState([]);
    const [onAirGame, setOnAirGame] = useState([]);
    const [pendingGame, setPendingGame] = useState([]);
    const [topUser, setTopUser] = useState([]);
    const [username, setUsername] = useState('');

    const fetchGames = async () => {
        try {
            const { data } = await gameApi.gameList();
            const game = data.game;
            const endList = game.filter((el) => el.status === 3);
            const onair = game.filter((el) => el.status === 2);
            const pending = game.filter((el) => el.status === 1);
            setOnAirGame(onair);
            setEndGameList(endList);
            setPendingGame(pending);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchTopUser = async (username) => {
        try {
            const { data } = await gameApi.topOfWeek(username);
            const dataUser = data.users;
            if (dataUser.length < 10) {
                dataUser.push(...new Array(10 - dataUser.length).fill({}));
            }
            setTopUser(data.users);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchGames();
        fetchTopUser(username);
    }, [username]);
    return (
        <div>
            <Header />
            <div className="flex flex-col gap-3 justify-between h-full md:w-[768px] lg:w-[1024px] md:mx-auto pt-16 pb-10">
                {pendingGame.length > 0 && (
                    <div className="p-4">
                        <h3 className="font-bold text-center">Sắp diễn ra</h3>

                        <PendingGame pendingGame={pendingGame} />
                    </div>
                )}
                <div className="p-4">
                    <TopUserWinMonth />
                </div>
                <div className="p-4">
                    <MonthEvent />
                </div>
                {onAirGame.length > 0 && (
                    <div className="p-4">
                        <div className="flex justify-center items-center gap-2">
                            <h3 className="font-bold text-center">
                                Đang diễn ra
                            </h3>
                            <span className="relative flex h-3 w-3">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </div>
                        <OnAirGame onLiveGame={onAirGame} />
                    </div>
                )}
                <TopOfWeek
                    topUser={topUser}
                    setUsername={setUsername}
                    username={username}
                />
                {endGameList.length > 0 && onAirGame.length === 0 && (
                    <div className="p-4">
                        <h3 className="font-bold">Game gần đây</h3>
                        <EndGame endGame={endGameList} />
                    </div>
                )}
            </div>
            <JoinZalo linkZalo={'https://zalo.me/g/agklpu854'} />
        </div>
    );
}

export default Game;
