import React, { useEffect, useState } from 'react';
import categoryApi from '../../../api/categoryApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUp,
    faChevronRight,
    faPercent,
    faStar,
} from '@fortawesome/free-solid-svg-icons';
import shopeApi from '../../../api/shopeeApi';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ShopLoading from './ShopLoading';
import { clickAffLink } from '../../../components/Ecommerce/ProductCard';
import Modal from '../../../components/Modal';
import JoinGroup from '../../../components/Modal/popup/JoinGroup';

function Categories({ categoryList, platform }) {
    const [categories, setCategories] = useState([]);
    const { isLogined } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchCategories = async () => {
            setIsLoading(true);
            try {
                const { data } = await categoryApi.getCategory(
                    categoryList || [11036279, 11035639, 11035567, 11036345]
                );
                setCategories(data.categories);
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };
        fetchCategories();
    }, [categoryList]);

    const screenWidth = window.innerWidth;
    let shopNums = 8;

    if (screenWidth > 639) shopNums = 12;

    const handleSelectShop = async (link) => {
        if (!isLogined) return navigate(`/login?redirect=${platform}`);
        try {
            const { data } = await shopeApi.getLinkAff(link);
            console.log(data);
            const linkAff = data.linkAff.batchCustomLink[0].longLink;
            if (linkAff) clickAffLink(linkAff);
        } catch (error) {
            console.log(error);
        }
        const randomNum = Math.random();
        if (randomNum >= 0.7) setOpenModal(true);
    };

    return (
        <div className="flex flex-col gap-6">
            {categories.length > 0 &&
                categories.map((el) => (
                    <div
                        key={el.categoryId}
                        className={`${
                            el.categoryId === `11036345`
                                ? 'order-2'
                                : el.categoryId === '11036279'
                                ? 'order-1'
                                : 'order-3'
                        }`}
                    >
                        <div className="flex justify-between items-center">
                            <h2 className="p-1 font-semibold">
                                {el.categoryName}
                            </h2>
                            <Link
                                to={el.categoryId}
                                className="flex gap-1 items-center hover:underline hover:text-primary-600 cursor-pointer"
                            >
                                <span className="text-sm">Xem thêm</span>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Link>
                        </div>
                        <div className="flex flex-wrap">
                            {[...el.shop].splice(0, shopNums).map((shop) => (
                                <div
                                    onClick={() =>
                                        handleSelectShop(shop.shopUrl)
                                    }
                                    key={shop.shopId}
                                    className="hover:bg-slate-100 flex  flex-col relative w-1/4 sm:w-1/6 md:w-1/6 lg:w-1/6 bg-white border-r border-b last:border-r-0"
                                >
                                    <img
                                        className="object-contain p-6"
                                        src={shop.shopLogoUrl}
                                        alt="shop-logo"
                                    />
                                    <div className="flex flex-col w-full items-end text-xs px-1 pb-1 pt-2">
                                        <span className="flex flex-1 justify-center items-center">
                                            <span>{shop.rating * 1}</span>
                                            <span className="text-primary-600">
                                                <FontAwesomeIcon
                                                    size="xs"
                                                    icon={faStar}
                                                />
                                            </span>
                                        </span>
                                        <span className="flex-1 flex justify-center items-center">
                                            <span>
                                                <FontAwesomeIcon
                                                    size="xs"
                                                    className="rotate-45 text-slate-900 mr-[2px]"
                                                    icon={faArrowUp}
                                                />
                                            </span>
                                            <span className="text-primary-600">
                                                {(
                                                    (el.merchantRate * 1 +
                                                        shop.sellerCommission *
                                                            1) *
                                                    0.8
                                                ).toFixed(1)}
                                            </span>
                                            <span className="text-primary-600">
                                                <FontAwesomeIcon
                                                    icon={faPercent}
                                                />
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            <ShopLoading isLoadingShop={isLoading} />
            <Modal isOpen={openModal}>
                <JoinGroup onClose={() => setOpenModal(false)} />
            </Modal>
        </div>
    );
}

export default Categories;
