import React, { useRef, useState } from 'react';
import shopeApi from '../../api/shopeeApi';
import copy from 'copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
const notify = () =>
    toast.success('Đã Copy!', {
        position: 'top-center',
        autoClose: 3000,
        theme: 'dark',
    });
function ConvertLink() {
    const [input, setInput] = useState('');
    const inputRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const onConvert = async () => {
        setIsLoading(true);
        try {
            const regexURL = /https:\/\/[^\s]+/g;
            const matches = input.match(regexURL);
            if (!matches) return setIsLoading(false);
            const { data } = await shopeApi.convertUrlAff(matches);
            const newUrlList = data.newLinkList;
            let cloneInput = input;
            for (let i = 0; i < matches.length; i++) {
                cloneInput = cloneInput.replace(
                    matches[i],
                    newUrlList[i] || 'LINK GỐC KHÔNG HỢP LỆ!'
                );
            }
            inputRef.current.value = cloneInput;

            toast.success('Chuyển đổi thành công!', {
                position: 'top-center',
                autoClose: 3000,
                theme: 'dark',
            });
        } catch (error) {
            console.log(error);
            toast.error('Lỗi chuyển đổi!', {
                position: 'top-center',
                autoClose: 3000,
                theme: 'dark',
            });
        }
        setIsLoading(false);
    };

    const handleOnPaste = (e) => {
        const value = e.clipboardData.getData('text');
        setInput(value);
    };

    const onCopy = () => {
        const text = inputRef.current.value;
        if (!text) return;
        copy(text);
        notify();
    };
    return (
        <div className="p-4 flex flex-col gap-3">
            <h1 className="text-center font-bold text-xl">
                Chuyển đổi link AFFILIATE
            </h1>
            <div>
                <textarea
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onPaste={handleOnPaste}
                    name="input"
                    rows={12}
                    className="w-full rounded-md p-2"
                    placeholder="Nhập nội dung"
                ></textarea>
            </div>
            <div className="flex justify-center">
                <button
                    disabled={isLoading}
                    onClick={onConvert}
                    className={`p-3 bg-primary-600 text-white rounded-md min-w-[40px] ${
                        isLoading && 'opacity-60'
                    }`}
                >
                    {isLoading ? (
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            className="animate-spin"
                        />
                    ) : (
                        'CHUYỂN ĐỔI'
                    )}
                </button>
            </div>
            <div>
                <textarea
                    ref={inputRef}
                    name="input"
                    rows={12}
                    className="w-full rounded-md p-2"
                    placeholder="Nhập nội dung"
                ></textarea>
            </div>
            <div className="flex justify-center">
                <button
                    onClick={onCopy}
                    className="p-3 bg-primary-600 text-white rounded-md"
                >
                    SAO CHÉP
                </button>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ConvertLink;
