import axiosClient from './axiosClient';

const categoryApi = {
    getCategory: (data) => {
        const baseURL = `category?categories=${data.join(',')}`;
        return axiosClient.get(baseURL);
    },
};

export default categoryApi;
