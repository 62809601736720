import React from 'react';

function TimeLine({ commitTime, withdrawTime }) {
    return (
        <div className="w-full text-sm">
            <div className="w-full relative text-sm">
                <div className="w-full border border-primary-500 absolute top-1/2 -translate-y-1/2"></div>
                <div className="flex justify-between p-2">
                    <div className="p-1 flex flex-col justify-between items-center gap-2">
                        <div className="flex justify-center items-center relative">
                            <span>Mua</span>
                        </div>
                        <span className="text-white bg-primary-600 px-1 rounded-md">
                            Hôm nay
                        </span>
                    </div>
                    <div className="p-1 flex flex-col justify-between items-center">
                        <div className="flex justify-center items-center relative">
                            <span>Ghi nhận</span>
                        </div>
                        <span className="text-white bg-primary-600 px-1 rounded-md">
                            {commitTime || '1 ngày'}
                        </span>
                    </div>
                    <div className="p-1 flex flex-col justify-between items-center">
                        <div className="flex justify-center items-center relative">
                            <span>Có thể rút</span>
                        </div>
                        <span className="text-white bg-primary-600 px-1 rounded-md">
                            {withdrawTime || '30 ngày'}
                        </span>
                    </div>
                </div>
            </div>
            <div className="p-2 bg-white rounded-md shadow-sm">
                <span className="font-semibold">Ví dụ:</span>
                <div>
                    <span>Bạn mua Hôm nay</span>
                </div>
                <div>
                    <span>{`Đơn của bạn sẽ được ghi nhận ${
                        commitTime || 'sau 1 ngày'
                    }`}</span>
                </div>
                <div>
                    <span>{`Bạn có thể rút sau ${withdrawTime} Kể từ thời điểm ĐÃ NHẬN HÀNG`}</span>
                </div>
            </div>
        </div>
    );
}

export default TimeLine;
