const { default: axiosClient } = require('./axiosClient');

const shopeeVideoApi = {
    myVideos() {
        const baseURL = 'shopeevideo/my_videos';
        return axiosClient.get(baseURL);
    },
    uploadVideo(link) {
        const baseURL = 'shopeevideo/upload_video';
        return axiosClient.post(baseURL, { link });
    },
    uploadVideoV2(link) {
        const baseURL = 'shopeevideo/upload_video_v2';
        return axiosClient.post(baseURL, { link });
    },
    uploadVideoV3(productList) {
        const baseURL = 'shopeevideo/upload_video_v2';
        return axiosClient.post(baseURL, { productList });
    },
    findVideos(listVideo) {
        const baseURL = 'shopeevideo/find_videos';
        return axiosClient.post(baseURL, { listVideo });
    },
};

export default shopeeVideoApi;
