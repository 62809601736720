import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faZ } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

function JoinGroupMain({ zaloUrl, teleUrl, title }) {
    return (
        <div className="p-4 bg-white rounded-md text-center shadow-lg shadow-red-400 bg-gradient-to-tr from-red-300 via-amber-200 to-yellow-400">
            <h3 className="p-2 font-bold">
                {title || 'Nhập hội săn xu cùng tui nhaaaa'}
            </h3>
            <div className="flex justify-between">
                <Link
                    to={zaloUrl || 'https://zalo.me/g/agklpu854'}
                    target="_blank"
                    className="flex flex-col items-center flex-1 p-3 cursor-pointer rounded-md hover:border"
                >
                    <FontAwesomeIcon
                        icon={faZ}
                        className="text-4xl text-blue-500"
                    />
                    <span>Nhóm Zalo</span>
                </Link>
                <Link
                    to={teleUrl || 'https://t.me/chietkhaupro'}
                    target="_blank"
                    className="flex flex-col items-center flex-1  p-3 cursor-pointer rounded-md hover:border"
                >
                    <FontAwesomeIcon
                        icon={faTelegram}
                        className="text-4xl text-blue-400"
                    />
                    <span>Nhóm Telegram</span>
                </Link>
            </div>
        </div>
    );
}

export default JoinGroupMain;
