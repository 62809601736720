import React, { useEffect, useState } from 'react';

import VideoGuide from '../VideoGuide';
// import MyVideos from '../MyVideos';
import shopeeVideoApi from '../../../../api/shopeeVideoApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import ProductVideoInputNoLogin from './ProductInputNoLogin';
import videosFilterPostId from '../../../../utils/videoFilterPostId';
import shopeApi from '../../../../api/shopeeApi';
import Modal from '../../../../components/Modal';
import RedirectPopup from '../../../../components/Ecommerce/RedirectPopup';

const clickAffLink = (url) => {
    // setTimeout(() => {
    //     window.open(url, '_blank');
    // }, 100);
    window.location.href = url;
};

function ShopeeVideoMultiLinkNoLogin() {
    // const REQUIRED_VIDEO_NUMS = 1;
    const COUNTDOWN_TIME = 0.1; // Minutes
    const [clock, setClock] = useState(Date.now());
    const [onGetLink, setOnGetLink] = useState(false);

    const [videoList, setVideoList] = useState([]);
    const [errorMsg] = useState('');
    const [productList, setProductList] = useState(
        localStorage.getItem('videoProductList')
            ? JSON.parse(localStorage.getItem('videoProductList'))
            : []
    );
    const [isLoading] = useState(false);

    // const fetchVideos = async (listVideo) => {
    //     // const listvideoLocal = localStorage.getItem('listVideo');
    //     try {
    //         const { data } = await shopeeVideoApi.findVideos(listVideo);
    //         setVideoList(data.videos);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const videosFilter = videosFilterPostId(videoList);

    const onGoToVideo = async (postId) => {
        const SHOPEE_VIDEO_URL = 'https://sv.shopee.vn/share-video/';
        const videoUrl = SHOPEE_VIDEO_URL + postId;
        setOnGetLink(true);
        try {
            const { data } = await shopeApi.getLinkAff(videoUrl);
            const linkAff = data.linkAff.batchCustomLink[0].longLink;
            console.log(linkAff);
            // if (linkAff) {
            //     clickAffLink(linkAff);
            // }
        } catch (error) {
            console.log(error);
        }
        clickAffLink(videoUrl);
        setOnGetLink(false);
    };

    // const onCreateVideo = async () => {
    //     if (productList.length < REQUIRED_VIDEO_NUMS) return;
    //     setErrorMsg('');
    //     setIsLoading(true);
    //     try {
    //         const { data } = await shopeeVideoApi.uploadVideoV3(productList);
    //         const localProductList = [
    //             ...data.newPost.map((el) => el.itemId),
    //             videoList.map((el) => el.itemId),
    //         ];
    //         console.log(localProductList);
    //         localStorage.setItem('listVideo', JSON.stringify(localProductList));
    //         await fetchVideos(localProductList);
    //     } catch (error) {
    //         console.log(error);
    //         handleErrorAsync(error, setErrorMsg);
    //     }
    //     setIsLoading(false);
    // };

    useEffect(() => {
        const fetchVideos = async () => {
            const listvideoLocal = localStorage.getItem('listVideo');
            try {
                const { data } = await shopeeVideoApi.findVideos(
                    listvideoLocal ? JSON.parse(listvideoLocal) : []
                );
                setVideoList(data.videos);
            } catch (error) {
                console.log(error);
            }
        };
        fetchVideos();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setClock(Date.now());
        }, [1000]);

        return () => {
            clearInterval(timer);
        };
    });

    const onRemoveProductList = async () => {
        localStorage.removeItem('videoProductList');
        setProductList([]);
    };
    return (
        <div className="flex flex-col">
            <div className="pt-6 flex flex-col p-4 gap-2 md:w-[768px] lg:w-[1024px] md:mx-auto">
                <VideoGuide />
                <ProductVideoInputNoLogin
                    productList={productList}
                    setProductList={setProductList}
                />
                {errorMsg && (
                    <div className="py-2 flex items-center gap-1 text-red-600 text-sm">
                        <FontAwesomeIcon icon={faXmarkCircle} />
                        <span>{errorMsg}</span>
                    </div>
                )}
                {productList.length > 0 && (
                    <div className="p-2">
                        <div className="flex gap-2 items-center">
                            <h3 className="font-semibold py-2">{`Danh sách sản phẩm (${productList.length}/6)`}</h3>
                            <div>
                                <button
                                    onClick={onRemoveProductList}
                                    className="px-1 rounded-lg bg-gray-300 shadow-sm"
                                >
                                    Xóa tất cả
                                </button>
                            </div>
                        </div>
                        <div className="p-2 bg-white rounded-lg border shadow-md flex flex-col gap-3">
                            {productList.map((product) => (
                                <div
                                    key={product.itemId}
                                    className="flex gap-2 border-b last:border-none"
                                >
                                    <img
                                        className="h-12 w-12 rounded object-cover"
                                        src={product.imageUrl}
                                        alt="product"
                                    />
                                    <span className="line-clamp-2 overflow-hidden text-ellipsis">
                                        {product.productName}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-end py-2 w-full">
                            <button
                                disabled={isLoading}
                                // onClick={onCreateVideo}
                                className="w-24 h-12 bg-primary-600 text-white rounded-lg border shadow-lg flex justify-center items-center disabled:opacity-60"
                            >
                                {isLoading ? (
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        className="animate-spin"
                                    />
                                ) : (
                                    'Tạo Video'
                                )}
                            </button>
                        </div>
                    </div>
                )}
                <h3 className="py-2 px-1 font-semibold">
                    Video gần đây của bạn
                </h3>
                {/* <div>
                    <MyVideos
                        videoList={videoList}
                        setVideoList={setVideoList}
                    />
                </div> */}
                <div className="flex flex-col gap-2">
                    {Object.keys(videosFilter).map((el, index) => (
                        <div className=" flex flex-col gap-2 bg-white rounded-lg border shadow-md p-2">
                            <span className="font-semibold">{`Video: #${
                                index + 1
                            }`}</span>

                            <div key={el} className="flex flex-col gap-3">
                                {videosFilter[el].map((product) => (
                                    <div
                                        key={product.itemId}
                                        className="flex gap-2 border-b last:border-none"
                                    >
                                        <img
                                            className="h-12 w-12 rounded object-cover"
                                            src={product.imageUrl}
                                            alt="product"
                                        />
                                        <span className="line-clamp-2 overflow-hidden text-ellipsis">
                                            {product.productName}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-end">
                                <button
                                    disabled={
                                        clock <
                                        videosFilter[el][0].createTime +
                                            COUNTDOWN_TIME * 60 * 1000
                                    }
                                    onClick={() => onGoToVideo(el)}
                                    className="p-2 w-24 h-10 bg-primary-600 font-semibold text-white rounded-md disabled:opacity-60 disabled:cursor-not-allowed"
                                >
                                    {clock >=
                                    videosFilter[el][0].createTime +
                                        COUNTDOWN_TIME * 60 * 1000 ? (
                                        'Mua ngay'
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            className="animate-spin"
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal isOpen={onGetLink}>
                <RedirectPopup platform={'Shopee'} />
            </Modal>
        </div>
    );
}

export default ShopeeVideoMultiLinkNoLogin;
