import { faHelmetSafety, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
const baseUrlImage = 'https://down-ws-vn.img.susercontent.com/';
const DEVICE_NUMBER = 2;

function GameType2({ userAnswers, game }) {
    return (
        <div>
            <div className="p-2 flex flex-col gap-2 max-h-[460px] overflow-auto">
                {userAnswers
                    .filter((user) => user.useranswers.length > 0)
                    .map((el) => (
                        <div
                            key={el.userId}
                            className="bg-slate-100 p-2 rounded-md flex flex-col gap-1"
                        >
                            <div className="grid grid-cols-3 items-center justify-between gap-1">
                                <div className="flex gap-1 items-center justify-start line-clamp-1">
                                    <div className="h-7 min-w-[28px] aspect-square rounded-full overflow-hidden">
                                        {el.avatar && (
                                            <img
                                                className="w-full h-full object-cover"
                                                src={baseUrlImage + el.avatar}
                                                alt="avatar"
                                            />
                                        )}
                                        {!el.avatar && (
                                            <div className="bg-gray-300 w-full h-full flex justify-center items-center">
                                                <FontAwesomeIcon
                                                    className="p-1"
                                                    icon={faUser}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <span className="line-clamp-1">
                                        {el.username}
                                    </span>
                                </div>
                                <span className="flex items-center justify-center whitespace-nowrap">
                                    Đã trả lời:{' '}
                                    <strong>
                                        {el.useranswers.length}/
                                        {game.requiredAnswer}
                                    </strong>
                                </span>
                                <div className="flex gap-2 items-center justify-end">
                                    <div className="flex gap-1 items-center text-orange-500 font-bold">
                                        <span>
                                            {el.useranswers.length <
                                                game.requiredAnswer &&
                                            game.status === 3 ? (
                                                <span className="text-red-500 bg-red-100 px-1 rounded-md text-xs">
                                                    Hụt
                                                </span>
                                            ) : (
                                                parseFloat(
                                                    Math.floor(
                                                        game.reward /
                                                            Math.pow(
                                                                DEVICE_NUMBER,
                                                                el.useranswers
                                                                    .slice(
                                                                        0,
                                                                        game.requiredAnswer
                                                                    )
                                                                    .filter(
                                                                        (
                                                                            correctAnswer
                                                                        ) =>
                                                                            !correctAnswer.isCorrectAnswer
                                                                    ).length
                                                            )
                                                    )
                                                ).toLocaleString('vi-VN')
                                            )}
                                        </span>
                                        <div className="h-6 w-6 flex justify-center items-center text-sm bg-amber-400 rounded-full">
                                            <FontAwesomeIcon
                                                className="text-orange-500"
                                                icon={faHelmetSafety}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-1 items-center flex-wrap">
                                {el.useranswers.map((answer) => (
                                    <div
                                        key={answer.userAnswerId}
                                        className={`h-5 w-5 rounded-full text-white font-semibold flex justify-center items-center text-xs ${
                                            answer.isCorrectAnswer
                                                ? 'bg-green-500'
                                                : 'bg-red-600'
                                        }`}
                                    >
                                        <span>
                                            {answer.questionId.split('-')[1]}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default GameType2;
