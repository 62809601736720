import { faHelmetSafety } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function PendingGame({ pendingGame }) {
    return (
        <div className="flex flex-col gap-4">
            {pendingGame.map((game) => (
                <div
                    key={game.gameId}
                    className="bg-white shadow-md shadow-pink-200 text-sm"
                >
                    <h4 className="text-center p-2 bg-gradient-to-tr  from-amber-300 to-amber-600 text-white rounded-t-md font-semibold border-b shadow-md">
                        {game.gameName}
                    </h4>
                    <p className="p-2">{game.describe}</p>
                    <div className="p-2 flex gap-2 items-center">
                        <span>Tiền tưởng dự kiến:</span>
                        <div className="flex gap-1 items-center text-orange-500 text-xl font-bold">
                            <span>
                                {parseFloat(game.reward).toLocaleString(
                                    'vi-VN'
                                )}
                            </span>
                            <div className="h-6 w-6 flex justify-center items-center text-sm bg-amber-400 rounded-full">
                                <FontAwesomeIcon
                                    className="text-orange-500"
                                    icon={faHelmetSafety}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PendingGame;
