import { faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    const fbPage = 'https://www.facebook.com/groups/chietkhaupro/';
    return (
        <footer className="text-xs text-gray-400 flex flex-col justify-end items-end">
            <div className="flex justify-end rounded-lg text-slate-800">
                <Link
                    className="h-8 aspect-square  flex justify-center items-center rounded-full hover:bg-primary-600 hover:text-white"
                    to="https://www.tiktok.com/@thocode1995"
                    target="_blank"
                >
                    <FontAwesomeIcon className="text-lg" icon={faTiktok} />
                </Link>
                <Link
                    className="h-8 aspect-square  flex justify-center items-center rounded-full hover:bg-primary-600 hover:text-white"
                    to={fbPage}
                    target="_blank"
                >
                    <FontAwesomeIcon className="text-lg" icon={faFacebookF} />
                </Link>
                <Link
                    className="h-8 aspect-square  flex justify-center items-center rounded-full hover:bg-primary-600 hover:text-white"
                    to={'https://zalo.me/g/hcxvof127'}
                    target="_blank"
                >
                    <FontAwesomeIcon
                        className="text-lg"
                        icon={faMobileScreen}
                    />
                </Link>
            </div>
            <div className="flex gap-2 text-sm text-slate-800">
                <Link to="/help" className="hover:underline">
                    Hướng dẫn sử dụng
                </Link>
                <Link to={'about'} className="hover:underline">
                    Giới thiệu
                </Link>
            </div>
            <div className="flex gap-1  ">
                <span>Được tạo bởi</span>
                <span className="font-semibold text-slate-900">
                    @thocode1995
                </span>
            </div>
        </footer>
    );
}

export default Footer;
